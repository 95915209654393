<template>
	<div class="HSMask">
		<div class="HSDialog" style="min-height: 220px;">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">{{dialogTitle}}</div>
				<img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogContent">
				<div class="HSDialogContentName">{{dialogContentName}}:</div>
				<el-input v-model="name" placeholder="请输入名称" style="width: 247px;"></el-input>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props:{
			addType:{   //新增 1为1级类目 2为2级类目  3为编辑类目  4为品牌 5为供应商  6为编辑品牌  7为职位 8为编辑职位
				default:0
			},
			nodeData:{   //返回父级类目信息
				default:()=>{
					return {}
				}
			}
		},
		data() {
			return {
				name:'',  //类型名字
				dialogTitle:'',  //弹框标题
				dialogContentName:'',  //弹框内容标题
			}
		},
		mounted(){
			if(this.addType == 3){
				this.name = this.nodeData.name
			}
			if(this.addType == 1 || this.addType == 2 || this.addType == 3){
				this.dialogTitle = '类型设置'
				this.dialogContentName = '类型名称'
			}else if(this.addType == 4){
				this.dialogTitle = '新增品牌'
				this.dialogContentName = '品牌名称'
			}else if(this.addType == 5){
				this.dialogTitle = '新增供应商'
				this.dialogContentName = '供应商名称'
			}else if(this.addType == 6){
				this.dialogTitle = '编辑品牌'
				this.dialogContentName = '品牌名称'
				this.name = this.nodeData.name
			}else if(this.addType == 7){
				this.dialogTitle = '新增职位';
				this.dialogContentName = '职位名称';
			}else if(this.addType == 8){
				this.dialogTitle = '编辑职位';
				this.dialogContentName = '职位名称';
				this.name = this.nodeData.name
			}
		},
		methods:{
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				if(this.name == ''){
					this.$message({
						message: '请输入类型名称',
						type: 'error'
					});
					return ;
				}
				if(this.addType == 1 || this.addType == 2 || this.addType == 4 || this.addType == 5 || this.addType == 7){  
					let params = {
						parentId:this.nodeData.id,
						companyInfo:this.nodeData.companyInfo,
						name:this.name,
					}
					if(this.nodeData.itemList.length == 0){
						params.order = 1
					}else{
						params.order = this.nodeData.itemList[0].order + 1
					}
					this.$http.post("/cms/System/AddSystemDiction", params).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '新增成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:res.data})
						}
					})
				}else if(this.addType == 3 || this.addType == 6 || this.addType ==8){
					let params = {
						id:this.nodeData.id,
						companyInfo:this.nodeData.companyInfo,
						name:this.name,
						code:this.nodeData.code,
						remark:this.nodeData.remark,
						order:this.nodeData.order,
						value:this.nodeData.value,
						param1:this.nodeData.param1,
						param2:this.nodeData.param2,
						isDef:this.nodeData.isDef
					}
					this.$http.post("/cms/System/UpdateSystemDiction", params).then(res => {
						if (res.code === 0) {
							this.$message({
								message: '修改成功',
								type: 'success'
							});
							this.$emit("submit",{type:'success',data:res.data})
						}
					})
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.HSDialogContent{
		height: 80px;
		display: flex;
		align-items: center;
		.HSDialogContentName{
			margin: 0 20px;
		}
	}
</style>
